import React from "react";
import BASE_URL from "../../constant/index";

const Herosection = () => {
  return (
    <div className="about-us d-flex justify-content-center align-items-center text-center">
      <div className="about-text">
        <h1 className="text-white text-center">About US</h1>
        <h3 className="text-white  text-center">
          Fox Trade is the Solution for all problems in your life.
        </h3>
        <h4 className="text-white  text-center">Restly > About Us</h4>
      </div>
      <img
        src={`${BASE_URL.BASE_URL}img/Group 22838@2x.png`}
        width="250px"
        height={"250px"}
        className="about-image"
      />
      <img
        src={`${BASE_URL.BASE_URL}img/Mask Group 39@2x.png`}
        width="5%"
        className="about-image-tow"
      />
    </div>
  );
};

export default Herosection;
