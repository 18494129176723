import React from 'react'
import { Row, Container, Col } from 'react-bootstrap'
import BASE_URL from '../../constant/index'
import { Link } from 'react-router-dom'

const HaveAnyQuestion = () => {
    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col lg={10} className='anyQuestion p-lg-5 p-3 d-lg-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-center text-center text-md-start'>
                   
                    <div>
                        <h2 className='text-white text-center mb-3 mb-md-0 h1'>Have any question or need any business consultation?</h2>
                    <div className='d-flex justify-content-center mt-lg-3'>
                        <Link to={`${BASE_URL.BASE_URL}contact`} className='Carousel-Button Contact_Us mt-3 mt-lg-0'>
                            Contact Us <img src={`${BASE_URL.BASE_URL}img/Group 23380.png`} width={"15px"} className='ms-2'alt='Logo'/>
                        </Link>
                    </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default HaveAnyQuestion
