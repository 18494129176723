import React, { useState } from 'react'
import BASE_URL from "../constant/index";
import API_URL from '../constant/index';
import Token from '../constant/index';
import { Col, Container, Row, Button, Card } from 'react-bootstrap';
import { MdWatchLater } from "react-icons/md";
import Form from 'react-bootstrap/Form';
import GetStarted from '../components/Aboutus/GetStarted';
import axios from 'axios';
import cogoToast from "cogo-toast";
import { FaInstagram, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Contact = () => {

  const [contactData, setContactData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    if (!contactData.firstName) {
      formIsValid = false;
      errors['firstName'] = 'First name is required';
    }

    if (!contactData.lastName) {
      formIsValid = false;
      errors['lastName'] = 'Last name is required';
    }

    if (!contactData.email) {
      formIsValid = false;
      errors['email'] = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(contactData.email)) {
      formIsValid = false;
      errors['email'] = 'Email is not valid';
    }

    if (!contactData.message) {
      formIsValid = false;
      errors['message'] = 'Message is required';
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactData({ ...contactData, [name]: value });
    if (!!errors[name]) setErrors({ ...errors, [name]: null });
  };




  const ContactUs = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    const URL = `${API_URL.API_URL}api_react/submit_contact`;

    const data = new FormData()
    data.append('f_name', contactData.firstName);
    data.append('l_name', contactData.lastName); // Check the key 'I_name' if it's correct
    data.append('email', contactData.email);
    data.append('message', contactData.message);

    axios.post(URL, data, {
      headers: {
        'Token': Token.Token,
      }
    })
      .then((res) => {
        console.log(res.data);
        // On successful API response, clear the form and re-enable the button
        if (res.data.status === 200) {
          cogoToast.success(res.data.message, { position: "bottom-right" });
          setContactData({
            firstName: '',
            lastName: '',
            email: '',
            message: '',
          });
        } else {
          cogoToast.error(res.data.message, { position: "bottom-right" });
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsSubmitting(false));
  }

  return (
    <div>
      <div className="Contact_us d-flex justify-content-center">
        <div className="about-text">
          <h1 className="text-white text-center">Contact Us</h1>
          <h4 className="text-white  text-center">
            We Deliver Better Growth With Your Investment
          </h4>
          <h4 className="text-white  text-center">Restly Contact Us</h4>
        </div>

        <img
          src={`${BASE_URL.BASE_URL}img/Mask Group 39@2x.png`}
          width="5%"
          className="about-image-tow"
          alt="How_We_Work_card_image"
        />
      </div>

      <div className='Get_in_touch p-5 mt-5'>
        <img
          src={`${BASE_URL.BASE_URL}img/Mask Group 38.svg`}
          width="300px"
          height={"300px"}
          className="bd_left_Image"
          alt="How_We_Work_card_image"
        />
        <img
          src={`${BASE_URL.BASE_URL}img/Group 23529.svg`}
          width="200px"
          height={"200px"}
          className="bd_right_Image"
          alt="How_We_Work_card_image"
        />
        <Container>
          <Row >
            <div style={{
              borderLeft: "7px solid black",
              paddingLeft: "30px"
            }}>
              <h1 className='title'>
                Enquiries? Get in touch.
              </h1>
              <h5>We would love to hear from you!</h5>
            </div>
          </Row>
          <div className='Get_in_touch_section'>
            <Row className='gy-5'>
              <Col lg="10" className='mx-auto'>
                <Row className="justify-content-center gap-5">
                  <Col lg="3" md="6" className='How_We_Work_card'>
                    <Card style={{ minHeight: '173px' }}>
                      <div className='How_We_Work_card_image'>
                        <img
                          src={`${BASE_URL.BASE_URL}img/Group 23789.svg`}
                          width="62px"
                          height={"62px"}
                          className="How_We_Work_card_image"
                          alt="How_We_Work_card_image"
                        />
                      </div>
                      <Card.Body className="mt-4">
                        <h4 className="text-center title">We Work 24/7</h4>
                        <Card.Text className="text-center">
                          We offer trading facilities 24 hours a day, five days a week.
                        </Card.Text>

                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg="3" md="6" className='How_We_Work_card'>
                    <Card style={{ minHeight: '173px' }}>
                      <div className='How_We_Work_card_image'>
                        <img
                          src={`${BASE_URL.BASE_URL}img/Group 23769.svg`}
                          width="62px"
                          height={"62px"}
                          className="How_We_Work_card_image"
                          alt="How_We_Work_card_image"
                        />
                      </div>
                      <Card.Body className="mt-4 text-center">
                        <h4 className="text-center title">Email</h4>
                        {/* <Card.Text className="text-center d-flex justify-content-center"> */}
                          <a href="mailto:support@fxt.exchange">support@fxt.exchange</a>
                        {/* </Card.Text> */}

                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg="3" md="6" className='How_We_Work_card'>
                    <Card style={{ minHeight: '173px' }}>
                      <div className='How_We_Work_card_image'>
                        <img
                          src={`${BASE_URL.BASE_URL}img/Group 23770.svg`}
                          width="62px"
                          height={"62px"}
                          className="How_We_Work_card_image"
                          alt="How_We_Work_card_image"

                        />
                      </div>
                      <Card.Body className="mt-4">
                        <h4 className="text-center title">Stay In Touch</h4>
                        <div className='d-flex align-items-center justify-content-center mt-3' style={{ gap: '15px' }}>
                          <a href="https://instagram.com/tradefox509" target="_blank" rel="noopener noreferrer">
                            <FaInstagram />
                          </a>
                          <a href="https://t.me/fxtexc" target="_blank" rel="noopener noreferrer">
                            <FaTelegramPlane />
                          </a>
                          {/* <a href="https://twitter.com/foxtrade5" target="_blank" rel="noopener noreferrer">
                            <FaXTwitter />
                          </a> */}
                          <a href="https://chat.whatsapp.com/CkUgaMwcZMrF2mCsdpRU77" target="_blank" rel="noopener noreferrer">
                            <FaWhatsapp />
                          </a>
                        </div>

                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

        </Container>
      </div>

      <div className='mb-5'>
        <Container>
          <Row>
            <Col sm="12" md='5' lg='5'>
              <div className="Contact_us_form">
                <div style={{
                  borderLeft: "7px solid white",
                  paddingLeft: "20px"
                }}>
                  <h1 className='text-white'>
                    Contact Us
                  </h1>
                  <h5 className='text-white'>If You Have Any Question About Us?</h5>
                </div>
                <p className='text-white ps-4'>For any kind of business solution and consultation don’t hesitate to contact us for immediate customer support. We would surely help you out.</p>
                <div className='Office_Hours mt-5 ps-4'>
                  <h5 className='text-white'><MdWatchLater /> Office Hours</h5>
                  <h4 className='text-white fw-bolder'>10:00am - 08:00pm</h4>
                  <p className='text-white'>We are always open except Saturday & Sunday from</p>
                </div>
              </div>
            </Col>

            <Col sm="12" md='7' lg='7'>
              <Form.Group className="mb-4 mt-4 mt-lg-0">
                <Form.Control
                  className="p-3"
                  type="text"
                  id="First-Name"
                  aria-describedby="nameHelpBlock"
                  placeholder="First Name*"
                  name="firstName"
                  value={contactData.firstName}
                  onChange={handleInputChange}
                  isInvalid={!!errors.firstName}
                />
                {errors.firstName && <Form.Text style={{ color: 'red' }}>
                  {errors.firstName}
                </Form.Text>}
                {/* {errors.firstName && <div style={{ color: 'red' }}></div>} */}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Control
                  className="p-3"
                  type="text"
                  id="Last-Name"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Last Name*"
                  name="lastName"
                  value={contactData.lastName}
                  onChange={handleInputChange}
                />
                {errors.lastName && <Form.Text style={{ color: 'red' }}>
                  {errors.lastName}
                </Form.Text>}

              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Control
                  className="p-3"
                  type="email"
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Email*"
                  name="email"
                  value={contactData.email}
                  onChange={handleInputChange}
                />
                {errors.email && <Form.Text style={{ color: 'red' }}>
                  {errors.email}

                </Form.Text>}
              </Form.Group>
              <Form.Group className="mb-4" >
                <Form.Control
                  placeholder="Your Requirement"
                  // className="mb-4"
                  as="textarea"
                  rows={3}
                  name="message"
                  value={contactData.message}
                  onChange={handleInputChange}
                />
                {errors.message && <Form.Text style={{ color: 'red' }}>
                  {errors.message}
                </Form.Text>}
              </Form.Group>
              <Button className='primaryButton info' onClick={ContactUs} disabled={isSubmitting}> {isSubmitting ? 'Sending...' : 'Send'}</Button>
            </Col>
          </Row>
          {/* <ToastContainer />   */}
        </Container>
      </div>
      <GetStarted />

    </div>
  )
}

export default Contact
