import React from 'react'
import HeroSrction from '../components/HeroSrction'
import Homecard from '../components/Homecard'
import Overview from '../components/Overview'
import OurMission from '../components/OurMission'
import Factors from '../components/Factors'
import HowItWork from '../components/HowItWork'
import WhatWeDo from '../components/WhatWeDo'
import HaveAnyQuestion from '../components/ui/HaveAnyQuestion'

const Home = () => {
    return (
        <>
            <HeroSrction />
            <div className="overview">
                <Homecard />
                <Overview />
                <OurMission />
            </div>
            <Factors />
            <HowItWork/>
            <WhatWeDo/>
            <div className='haveanyquestion'>
                <HaveAnyQuestion />
            </div>  
            {/* <HaveAnyQuestion/> */}
        </>
    )
}

export default Home
