import React from 'react'
import { Row, Col } from 'react-bootstrap'
import BASE_URL from '../../constant/index'
import Carousel from 'react-bootstrap/Carousel';

const ThinktoKnow = () => {
    return (
        <div className='mainfactor pb-5'>
            <img src={`${BASE_URL.BASE_URL}img/Mask Group 41.png`} alt="" width={"100px"} className='keyLeftfactordivImage' />
            <div>
                <Row className='Think_to_know'>
                    <Col sm={12} mb={6} xs={12} lg={6} xl={6}>
                        <div className='p-5 whoweareListsection ms-4'>
                            <div style={{
                                borderLeft: "7px solid black",
                                paddingLeft: "30px"
                            }}>
                                <h1 className='title'>
                                    Things to Know
                                </h1>
                                <h5>What We Do</h5>
                            </div>
                            <p className='mt-3'>
                                At Fox Trade, we specialize in delivering comprehensive financial solutions for healthy monetary growth. With a team of dedicated analysts closely monitoring dynamic markets, we ensure informed decisions by staying updated on market trends and global events.Utilizing a well-structured framework and thorough research process, we tailor solutions to clients' specific financial needs, whether they seek wealth growth, asset protection, or retirement planning.
                                Our holistic approach to financial planning considers all aspects of clients' financial lives, including loans, investments, insurance, and succession planning. Through rigorous research and analysis, we identify optimal strategies, empowering clients to make confident financial decisions. Committed to personalized service, we provide ongoing support, regularly reviewing and adjusting financial plans to align with clients' evolving goals. We help clients achieve financial success and secure their future. Get in touch with us right now to find out more about our offerings and how we can help you achieve your financial objectives.
                            </p>
                            {/* <Button className='Subscribe mt-2'>
                                View More <img src={`${BASE_URL.BASE_URL}img/Arrow_white.png`} alt="" width={"20px"} className='ms-2 white_arrow' />
                            </Button> */}
                        </div>
                    </Col>
                    <Col sm={12} mb={6} xs={12} lg={6} xl={6} className='pt-5'>
                        <img src={`${BASE_URL.BASE_URL}img/Aboutus/Group 23738.png`} className='d-lg-none' alt="Investment" width={"100%"} />
                    </Col>
                </Row>
            </div>
            <div className='keyfactorSection'>
                {/* <Container> */}
                <Row>
                    <Col sm={12} mb={4} xs={12} lg={4} xl={4}>
                        <div className='keyfactordiv'>
                            <div className='textDiv keyfactorSectionTextdiv'
                            >
                                <h1 className='title'>
                                    Key Factors
                                </h1>
                                <h5>Here are some key factors to <br />consider</h5>
                            </div>
                            <img src={`${BASE_URL.BASE_URL}img/Aboutus/Group 23739.png`} alt="" width={"500px"} className='keyfactordivImage' />
                        </div>
                    </Col>
                    <Col sm={12} mb={8} xs={12} lg={8} xl={8}>
                        <Carousel>
                            <Carousel.Item>
                                <div className='thinkaboutcrousel pt-5 pb-5 ms-md-4'>
                                    <div className='d-flex mt-4'>
                                        <div className='numbersection'>
                                            <div className='number'>1</div>
                                        </div>
                                        <div>
                                            <div className='d-flex align-items-center'>
                                                <img src={`${BASE_URL.BASE_URL}img/Technology & Innovation.png`} alt="" width={"38px"} height="41px" className='me-2 img-fluid' />
                                                <h3 className='title mb-0'>Technology & Innovation:</h3>
                                            </div>
                                            <p>Embrace cutting-edge technology and innovative solutions to stay ahead of the curve and enhance efficiency in financial management.</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-4'>
                                        <div className='numbersection'>
                                            <div className='number'>2</div>
                                        </div>
                                        <div>
                                            <div className='d-flex align-items-center'>
                                                <img src={`${BASE_URL.BASE_URL}img/Partnerships and Collaborations.png`} alt="" width={"38px"} height="41px" className='me-2 img-fluid' />
                                                <h3 className='title mb-0'>Partnerships and Collaborations:</h3>
                                            </div>
                                            <p>
                                                Foster strategic alliances and collaborations with industry partners to expand networks, access new opportunities, and deliver value-added services to clients.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-4'>
                                        <div className='numbersection'>
                                            <div className='number'>3</div>
                                        </div>
                                        <div>
                                            <div className='d-flex align-items-center'>
                                                <img src={`${BASE_URL.BASE_URL}img/Aboutus/Group 23594.png`} alt="" width={"38px"} height="41px" className='me-2 img-fluid' />
                                                <h3 className='title mb-0'>Regulatory Compliance:</h3>
                                            </div>
                                            <p>Uphold stringent regulatory standards and compliance requirements to ensure integrity, security, and ethical conduct in financial operations</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-4'>
                                        <div className='numbersectionlast'>
                                            <div className='number'>4</div>
                                        </div>
                                        <div>
                                            <div className='d-flex align-items-center'>
                                                <img src={`${BASE_URL.BASE_URL}img/Education and Research Resources.png`} alt="" width={"38px"} height="41px" className='me-2 img-fluid' />
                                                <h3 className='title mb-0'>Long-Term Vision:</h3>
                                            </div>
                                            <p>Focus on sustainable growth, stability, and resilience by aligning strategies with long-term objectives and market trends for enduring success.</p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className='thinkaboutcrousel pt-5 pb-5 ms-md-4'>
                                    <div className='d-flex mt-4'>
                                        <div className='numbersection'>
                                            <div className='number'>5</div>
                                        </div>
                                        <div>
                                            <div className='d-flex align-items-center'>
                                                <img src={`${BASE_URL.BASE_URL}img/User Interface and Trading Tools.png`} alt="" width={"38px"} height="41px" className='me-2 img-fluid' />
                                                <h3 className='title mb-0'>User Interface and Trading Tools:</h3>
                                            </div>
                                            <p>Access intuitive interfaces, trading tools, customizable dashboards, and real-time data for enhanced user experience and informed decisions.</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-4'>
                                        <div className='numbersection'>
                                            <div className='number'>6</div>
                                        </div>
                                        <div>
                                            <div className='d-flex align-items-center'>
                                                <img src={`${BASE_URL.BASE_URL}img/Education and Research Resources.png`} alt="" width={"38px"} height="41px" className='me-2 img-fluid' />
                                                <h3 className='title mb-0'>Education and Research Resources:</h3>
                                            </div>
                                            <p>
                                            Get educational resources and research materials to enhance their comprehension of financial markets, trading strategies, and investment opportunities.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-4'>
                                        <div className='numbersection'>
                                            <div className='number'>7</div>
                                        </div>
                                        <div>
                                            <div className='d-flex align-items-center'>
                                                <img src={`${BASE_URL.BASE_URL}img/Security of Personal Information and Funds.png`} alt="" width={"38px"} height="41px" className='me-2 img-fluid' />
                                                <h3 className='title mb-0'>Security of Personal Information and Funds:</h3>
                                            </div>
                                            <p>Implement encryption, multi-factor authentication, and secure data storage to safeguard clients' personal information and funds effectively.</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-4'>
                                        <div className='numbersectionlast'>
                                            <div className='number'>8</div>
                                        </div>
                                        <div>
                                            <div className='d-flex align-items-center'>
                                                <img src={`${BASE_URL.BASE_URL}img/Platform Security and Reliability.png`} alt="" width={"38px"} height="41px" className='me-2 img-fluid' />
                                                <h3 className='title mb-0'>Platform Security and Reliability:</h3>
                                            </div>
                                            <p>Platform implements stringent security measures to prevent unauthorized access, fraud, and cyberattacks, conducting regular audits to uphold industry standards.</p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>


                    </Col>
                </Row>
                {/* </Container> */}
            </div>
        </div>
    )
}

export default ThinktoKnow
