import { Row, Col } from 'react-bootstrap'
import BASE_URL from '../constant/index'
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

const OurMission = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };
    
    return (
        <div className='ourmission mt-4'>

            {/* <Container> */}
            <Carousel activeIndex={index} fade interval={3000} onSelect={handleSelect} indicators={false}>
                <Carousel.Item>
                    <Row>
                        <Col md={10} >
                            <div className='ourmissiondiv pt-5 pb-5'>
                                <div
                                    className='ourmissiontitle ms-0 ms-md-5 ps-2'
                                >
                                    <div
                                        className='textDiv'
                                    >
                                        <h1 className='title' style={{ color: 'white' }}>
                                            Our Mission
                                        </h1>
                                        <h5 style={{ color: 'white' }}>At Fox Trade, we believe that we can do more.
                                        </h5>
                                    </div>
                                    <div className='divMargin'>
                                        <div className='divMarginHeignt' >
                                            <p style={{ color: 'white' }}>
                                                Our mission is to provide innovative financial solutions using advanced technology to simplify complex processes, enhance financial well-being, and promote economic growth
                                                We envision a world where access to financial services is seamless, inclusive, and accessible, promoting financial inclusion and economic prosperity. Our mission is to enable people and companies to realize their greatest potential.
                                            </p>
                                        </div>
                                        {/* <Link to={`${BASE_URL.BASE_URL}about`} className='Carousel-Button-tow mt-3'>
                                            Read More <img src={`${BASE_URL.BASE_URL}img/Group 23380.png`} width={"10%"} />
                                        </Link> */}
                                    </div>
                                </div>

                            </div>
                        </Col>
                        <Col md={2} className='d-none'>
                        </Col>
                    </Row>
                    <div className="mission-crouselimage">
                        <img src={`${BASE_URL.BASE_URL}img/Mission.png`} alt="sads" className='VisionIcons' />
                    </div>
                    <div className="mission-crousel">
                        {/* Mask Group 40.png */}
                        <img src={`${BASE_URL.BASE_URL}img/Mask-Group-10@2x.png`} alt="mission" className='VisionImages' />
                    </div>
                    <img src={`${BASE_URL.BASE_URL}img/Mask Group 42@2x.png`} alt="mission" width={'200px'} className='halfCircleImage' />
                    <img src={`${BASE_URL.BASE_URL}img/Mask Group 41@2x.png`} alt="mission" width={'70px'} className='halfCircleImageLeft' />
                </Carousel.Item>
                <Carousel.Item>
                    <Row>
                        <Col md={10} >
                            <div className='ourmissiondiv pt-5 pb-5'>
                                <div
                                    className='ourmissiontitle ms-0 ms-md-5 ps-2'
                                >
                                    <div className='textDiv'>
                                        <h1 className='title' style={{ color: 'white' }}>
                                            Our Vision
                                        </h1>
                                        <h5 style={{ color: 'white' }}>We believe that we can do more growth in your business</h5>
                                    </div>
                                    <div className='divMargin'>
                                        <div className='divMarginHeignt'>
                                            <p style={{ color: 'white' }}>
                                                Our Vision is to create a ‘financially’ literate society by making the process of investing simpler and more understandable to the masses. To help our customers realize their investment goals through practical and sensible advice, well focused financial plans and strong financial discipline.

                                                We aspire to be the most trusted wealth advisors in the world. This is a vision shared by our people and is predicted on a commitment to uphold the highest standard of conduct and service .
                                            </p>
                                        </div>
                                        {/* <Link to={`${BASE_URL.BASE_URL}about`} className='Carousel-Button-tow mt-3'>
                                            Read More <img src={`${BASE_URL.BASE_URL}img/Group 23380.png`} width={"10%"} />
                                        </Link> */}
                                    </div>
                                </div>

                            </div>
                        </Col>
                        <Col md={2} >
                        </Col>
                    </Row>
                    <div className="mission-crouselimage">
                        <img src={`${BASE_URL.BASE_URL}img/Vision.png`} alt="sads" className='VisionIcons' />
                    </div>
                    <div className="mission-crousel">
                        <img src={`${BASE_URL.BASE_URL}img/Mask Group 40.jpg`} alt="VisionImages" className='VisionImages' />
                    </div>
                    <img src={`${BASE_URL.BASE_URL}img/Mask Group 42@2x.png`} alt="VisionImages" width={'200px'} className='halfCircleImage' />
                    <img src={`${BASE_URL.BASE_URL}img/Mask Group 41@2x.png`} alt="VisionImages" width={'70px'} className='halfCircleImageLeft' />
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default OurMission
