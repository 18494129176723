import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';


const DelayedSuspense = ({ fallback, children }) => {
  const [isReady, setIsReady] = useState(false);
  const location = useLocation();


  useEffect(() => {
    // Reset isReady to false on every route change
    setIsReady(false);

    // Set a minimum display time for the loader
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer);
  }, [location]); // Re-run effect on location change

  if (!isReady) {
    return fallback;
  }

  return (
    <React.Suspense fallback={null}>
      {children}
    </React.Suspense>
  );
};

export default DelayedSuspense;
