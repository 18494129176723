import React from 'react'
import BASE_URL from "../../constant/index";
import { Col, Container, Row} from 'react-bootstrap';

const About_This_Service = ({ aboutitle, AboutService, AboutText, aboutImage }) => {
    return (
        <div>
            <div className='About_This_Service mt-5'>
                <Container>
                    <Row>
                        <Col sm={12} md={12} lg={6}>
                            <div className='p-4'>
                                <div style={{
                                    borderLeft: "7px solid black",
                                    paddingLeft: "30px"
                                }}>
                                    <h1 className='title'>
                                      {aboutitle}
                                    </h1>
                                    <h5>{AboutService}</h5>
                                </div>
                                <p className='mt-4'>
                                  {AboutText}
                                </p>
                            </div>

                        </Col>
                        <Col sm={12} md={12} lg={6}>
                            <img src={`${BASE_URL.BASE_URL}img/${aboutImage}`} width={'100%'} alt="Investment" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default About_This_Service
