import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import BASE_URL from '../constant/index'
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { IoMdMail } from "react-icons/io";
import { Link , useLocation } from 'react-router-dom'
import { FaInstagram, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export const Footer = () => {
    const location = useLocation();

    const isRoot = location.pathname === BASE_URL.BASE_URL;
    
    return (
        <div className='Footer'>
            <Container>
                <Row  className={`gy-4 mt-4 mt-md-0  ${isRoot ? 'pt-lg-5' : 'pt-2'}`}>
                    <Col md={6} lg={3} sm={6} style={{ borderRight: "1px solid #474646" }}>
                        <img src={`${BASE_URL.BASE_URL}img/image2.png`} alt='Logo' width='200px' className='img-fluid' />
                        <p className='title mb-0 mt-2'>Office Hours</p>
                        <h4 className='text-white mb-0'><img src={`${BASE_URL.BASE_URL}img/Path 26709.png`} width='20px' className='me-2' />10:00am - 08:00pm</h4>
                        <p className='text-white mb-0'>Saturday/Sunday Closed</p>
                    </Col>
                    <Col md={6} lg={3} sm={6} className='border-right-lg-up'>
                        <p style={{ color: "#7d7b7c" }} className='px-3'>
                            It's important to research and choose an investment partner that aligns with your financial goals, risk tolerance, and investment preferences.Now this is where Fox Trade will help you !!
                        </p>
                    </Col>
                    <Col md={6} lg={3} sm={6} style={{ borderRight: "1px solid #474646" }} className='d-flex justify-content-lg-center'>
                        <div>
                            <h5 className='text-white Quick-Links'>Quick Links</h5>
                            <div className=''>
                                <ul style={{
                                    listStyle: "none",
                                    color: "#a19d9d",
                                    padding: "0px"
                                }}>
                                    <li><HiOutlineArrowLongRight style={{ color: "#0094b9" }} className="me-3" />
                                        <Link to={`${BASE_URL.BASE_URL}`} style={{ textDacoration: 'none' }}>
                                            Home
                                        </Link></li>
                                    <li><HiOutlineArrowLongRight style={{ color: "#0094b9" }} className="me-3" />
                                        <Link to={`${BASE_URL.BASE_URL}about`}>
                                            About us
                                        </Link>
                                    </li>
                                    <li><HiOutlineArrowLongRight style={{ color: "#0094b9" }} className="me-3" />
                                        <Link to={`${BASE_URL.BASE_URL}crypto-trading`}>
                                            Investment
                                        </Link></li>
                                    <li><HiOutlineArrowLongRight style={{ color: "#0094b9" }} className="me-3" />
                                        <Link to={`${BASE_URL.BASE_URL}contact`}>
                                            Contact
                                        </Link>
                                    </li>

                                </ul>
                            </div>

                        </div>
                    </Col>
                    <Col md={6} lg={3} sm={6} className='d-flex justify-content-lg-end'>
                        <div className='FooterSupport'>
                            <h5 className='text-white'>Support</h5>
                            <a href={`mailto:${"support@fxt.exchang"}`} className='HeaderIconsLink text-white'><IoMdMail />{" "}support@fxt.exchange</a>
                            <div className='d-flex align-items-center gap-3 mt-3' style={{color:"white" , fontSize:"18px"}}>
                                <a href="https://instagram.com/tradefox509" target="_blank" rel="noopener noreferrer">
                                    <FaInstagram />
                                </a>
                                <a href="https://t.me/fxtexc" target="_blank" rel="noopener noreferrer">
                                    <FaTelegramPlane />
                                </a>
                                {/* <a href="https://twitter.com/foxtrade5" target="_blank" rel="noopener noreferrer">
                                    <FaXTwitter />
                                </a> */}
                                <a href="https://chat.whatsapp.com/CkUgaMwcZMrF2mCsdpRU77" target="_blank" rel="noopener noreferrer">
                                    <FaWhatsapp />
                                </a>

                            </div>
                        </div>

                    </Col>
                    <Col md={12}>
                        <div className='p-1 d-flex flex-wrap justify-content-md-between justify-content-center mt-2 align-items-center' style={{ borderTop: '1px solid #474646' }}>
                            <p className='mb-0' style={{ color: "#7d7b7c" }}>Copyright fxt.exchange © 2023</p>
                            <p className='mb-0' style={{ color: "#7d7b7c" }}><Link to={`${BASE_URL.BASE_URL}private-police`}>Privacy Policy</Link> | <Link to={`${BASE_URL.BASE_URL}terms-conditions`}>Terms of Use </Link>| <Link>FAQ</Link></p>
                            {/* <p className='mb-0' style={{ color: "#7d7b7c" }}><Link to={`${BASE_URL.BASE_URL}private-police`}>Private Police</Link> | <Link to={`${BASE_URL.BASE_URL}terms-conditions`}>Terms of Use </Link>| <Link to={`${BASE_URL.BASE_URL}faq`} >FAQ</Link></p> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
