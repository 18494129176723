import React from 'react'
import { Container, Row } from 'react-bootstrap'
import BASE_URL from '../../constant/index'

const Loader = () => (
    <div className="Loader">
        <Container >
            <Row className='w-100'>
                <div className='d-flex justify-content-center'>
                    <>
                        <div className="spinner-box">
                            <div>
                                <img src={`${BASE_URL.BASE_URL}img/image2.png`} width='200px' className='mb-3' />
                                <div className="pulse-container">
                                    <div className="pulse-bubble pulse-bubble-1"></div>
                                    <div className="pulse-bubble pulse-bubble-2"></div>
                                    <div className="pulse-bubble pulse-bubble-3"></div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </Row>
        </Container>
    </div>
)

export default Loader
