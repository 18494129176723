import React from 'react'
import BASE_URL from "../../constant/index";
import GetStarted from '../../components/Aboutus/GetStarted'
import Currency_Trading from './Currency_Trading';
import About_This_Service from './About_This_Service';
import How_We_Work from '../Aboutus/How_We_Work';
const ReaclEstate = () => {
    const title="Real Estate"
    const text ="Real estate investment involves purchasing, owning, managing, renting, or selling properties for profit. It is a diverse asset class that encompasses residential homes, commercial buildings, industrial spaces, and land development projects."
    const image="Group 23755.png"
    const aboutitle = "About This Service"
    const AboutService = 'Real estate investing involves acquiring tangible assets through private sales, auctions, and agents, unlike traditional stock exchanges, which operate in a centralized marketplace.'
    const AboutText = "Real estate offers diverse investment options, including residential properties, commercial spaces, industrial warehouses, and land development projects. Each option presents unique opportunities and risks, and our team provides expert guidance to help clients navigate these choices and make informed investment decisions. Our team of experienced real estate professionals conducts thorough market research and analysis to identify promising investment opportunities. We offer personalized investment strategies that align with our clients' financial goals, risk tolerance, and investment preferences. Additionally, we guide property selection, acquisition, financing, and management, ensuring our clients maximize their returns on investment."
    const aboutImage = "Group 23752.png"

    return (
        <div>
            <div className="realEstate d-flex justify-content-center">
                <div className="about-text">
                    <h1 className="text-white text-center">Real Estate</h1>
                    <h3 className="text-white  text-center">
                        Fox Trade is the Solution for all problems in your life.
                    </h3>
                    <h4 className="text-white  text-center">Restly Investment Real Estate</h4>
                </div>

                <img
                    src={`${BASE_URL.BASE_URL}img/Mask Group 39@2x.png`}
                    width="5%"
                    className="about-image-tow"
                />
            </div>

            <Currency_Trading title={title} text={text} image={image} />
            <About_This_Service aboutitle={aboutitle} AboutService={AboutService} AboutText={AboutText} aboutImage={aboutImage}/>
            <How_We_Work />
            <GetStarted />
        </div>
    )
}

export default ReaclEstate
