// const BASE_URL = "/fox_exchange/v1/";

const Token = "4eb2ec622161117a1115748145ea06ad";
const API_URL = "https://backoffice.fxt.exchange/";


const BASE_URL = "/";

export default {
  BASE_URL: BASE_URL,
  API_URL: API_URL,
  Token,Token
};

