import React from 'react'
import { Row, Col, Button, Container } from 'react-bootstrap'
import BASE_URL from '../../constant/index'
import { MdOutlineTaskAlt } from "react-icons/md";
import { MdArrowRightAlt } from "react-icons/md";
import OurVisionTabs from './OurVisionTabs';


const WhoWeAre = () => {
    return (
        <div className='pardeep'> 
            <Container className=' '>
                <Row className='' style={{ marginTop: '6rem' }}>
                    <Col sm={12} mb={6} lg={6} xl={6}>
                        <img src={`${BASE_URL.BASE_URL}img/Aboutus/Group 23736.png`} alt="" className='AboutUsImage d-lg-none' />
                    </Col>
                    <Col sm={12} mb={5} xs={12} lg={5} xl={5}>
                        <div className='pt-5 pe-5 pb-5 ps-lg-0 ps-5 whoweareListsection'>
                            <div style={{
                                borderLeft: "7px solid black",
                                paddingLeft: "30px"
                            }}>
                                <h1 className='title'>
                                    About Us
                                </h1>
                                <h5>Who We Are ?</h5>
                            </div>
                            <p className='mt-3'>
                                At Fox Trade, we are more than just a financial services company; we are your trusted partner in wealth management. With a deep commitment to excellence and a passion for helping our clients achieve their financial aspirations, we specialize in providing bespoke solutions for individuals and institutions seeking to maximize their wealth potential.
                                With Fox Trade, you can rest assured that your financial future is in capable hands. Contact us today to discover how we can help you unlock your full wealth potential and achieve your financial dreams.

                            </p>
                            <ul className='whoweareList'>
                                <li className='mb-2'><MdOutlineTaskAlt style={{ color: '#0094b9' }} className="me-3" />Your Partner in Financial Success</li>
                                <li className='mb-2'><MdOutlineTaskAlt style={{ color: '#0094b9' }} className="me-3" />Personalized wealth management for you</li>
                                <li className='mb-2'><MdOutlineTaskAlt style={{ color: '#0094b9' }} className="me-3" />Committed to excellence and delivering results.</li>
                                <li className='mb-2'><MdOutlineTaskAlt style={{ color: '#0094b9' }} className="me-3" />Long-Term Partnerships, Trusted Financial Advisors.</li>
                                <li className='mb-2'><MdOutlineTaskAlt style={{ color: '#0094b9' }} className="me-3" />Experienced professionals empower informed decisions.</li>
                            </ul>
                            <Button className='Subscribe mt-4 mb-4' style={{visibility:'hidden'}}>
                                View More <MdArrowRightAlt />
                            </Button>
                        </div>
                    </Col>
                </Row>
                {/* <OurVisionTabs/> */}
            </Container>
            <OurVisionTabs/>
        </div>
    )
}

export default WhoWeAre
