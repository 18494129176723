import React from 'react'
import { Link } from 'react-router-dom'
import BASE_URL from '../../constant/index'
const GetStarted = () => {
    return (
        <div className='d-flex justify-content-center getStarted p-5'>
            <div>
                <h1 className='text-center text-white'>Get Started Today</h1>
                <h5 className='text-center text-white'>" To be successful in trading, you must have an unquenchable thirst for knowledge and information. !! "</h5>
                <div className='d-flex justify-content-center'>
                    <Link to={`https://backoffice.fxt.exchange/register`} className='Carousel-Button mt-4'>
                        Let's Go <img src={`${BASE_URL.BASE_URL}img/Group 23380.png`} width={"15px"} className='ms-2'  alt='Get Started Today'/>
                    </Link>
                </div>

            </div>
        </div>
    )
}

export default GetStarted
