import React from 'react'
import { Col, Row, Container } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import BASE_URL from '../../constant/index'

const OurVisionTabs = () => {
    return (
        <div className='tabs mt-5'>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={11} className='mx-auto'>
                        <Tabs
                            defaultActiveKey="Our_Vision"
                            id="uncontrolled-tab-example"
                            className=""
                        >
                            <Tab eventKey="Our_Vision" title="Our Vision">
                                <div className='d-flex'>
                                    <div className='innnerTabsImage p-4 d-flex justify-content-center align-items-center'>
                                        <img src={`${BASE_URL.BASE_URL}img/Aboutus/Group 23576.png`} alt="" width={"80%"} />
                                    </div>
                                    <div className='innerTabContent p-4 d-flex justify-content-start align-items-center'>
                                        <p className='m-0 text-white text-left'>Empowering individuals and businesses to achieve financial prosperity through innovative solutions and personalized guidance.</p>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Our_Mission" title="Our Mission">
                                <div className='d-flex'>
                                    <div className='innnerTabsImage p-4 d-flex justify-content-center align-items-center'>
                                        <img src={`${BASE_URL.BASE_URL}img/Group 1000003857.png`} alt="" width={"80%"} />
                                    </div>
                                    <div className='innerTabContent p-4 d-flex justify-content-start align-items-center'>
                                        <p className='m-0 text-white'>To be the leading provider of wealth management services, recognized for our commitment to excellence, integrity, and client satisfaction.</p>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Overview" title="Overview" >
                                <div className='d-flex'>
                                    <div className='innnerTabsImage p-4 d-flex justify-content-center align-items-center'>
                                        <img src={`${BASE_URL.BASE_URL}img/Group 1000003858.png`} alt="" width={"80%"} />
                                    </div>
                                    <div className='innerTabContent p-4 d-flex justify-content-start align-items-center'>
                                        <p className='m-0 text-white'>We specialize in private wealth management, offering tailored investment plans and expert guidance to help clients navigate the complexities of financial markets and achieve their long-term goals.</p>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
            <img src={`${BASE_URL.BASE_URL}img/Mask Group 58.png`} alt="" width={"100px"} className='Mask_Group'/>
        </div>
    )
}

export default OurVisionTabs
