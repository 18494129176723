import React from 'react'
import BASE_URL from "../../constant/index";
import GetStarted from '../../components/Aboutus/GetStarted'
import Currency_Trading from './Currency_Trading';
import About_This_Service from './About_This_Service';
import How_We_Work from '../Aboutus/How_We_Work';

const Cryptotrading = () => {
    const text = "Crypto trading involves buying, selling, and exchanging digital currencies 24/7 on decentralized exchanges, using technical analysis, fundamental analysis, and market sentiment to make informed decisions."
    const title = "Crypto Trading"
    const image = "Group 23741.png"
    const aboutitle = "About This Service"
    const AboutService = 'Cryptocurrency trading involves buying and selling cryptocurrencies on exchanges, using CFDs for price speculation. Decentralized markets, verified through mining, ensure efficiency and security.'
    const AboutText = "The cryptocurrency market operates through a decentralized system of peer-to-peer transaction checks, with transactions added to the blockchain through mining. Here, our team of analysts and traders monitors the market to identify profitable trading opportunities. They provide clients with access to advanced trading platforms and tools, conducting thorough research to assess potential risks and rewards. Fox Trade stays informed about market trends, technological advancements, and regulatory developments, empowering clients to make informed trading decisions. With comprehensive services and expert guidance, clients can trade cryptocurrencies confidently and achieve their financial goals."
    const aboutImage = "Group 23742.png"
    return (
        <>
            <div className="Crypto d-flex justify-content-center">
                <div className="about-text">
                    <h1 className="text-white text-center">Crypto Trading</h1>
                    <h3 className="text-white  text-center">
                        Fox Trade is the Solution for all problems in your life.
                    </h3>
                    <h4 className="text-white  text-center">Restly > Investment > Crypto Trading</h4>
                </div>

                <img
                    src={`${BASE_URL.BASE_URL}img/Mask Group 39@2x.png`}
                    width="5%"
                    className="about-image-tow"
                    alt='Investment'
                />
            </div>
            <Currency_Trading title={title} text={text} image={image} />
            <About_This_Service aboutitle={aboutitle} AboutService={AboutService} AboutText={AboutText} aboutImage={aboutImage} />
            <div className='How_We_Work_bg'>
                <How_We_Work />
                <GetStarted />
            </div>
        </>
    )
}

export default Cryptotrading
