import React from 'react'
import BASE_URL from "../../constant/index";
import { Col, Container, Row } from 'react-bootstrap';
import { Link ,useLocation } from 'react-router-dom'
import { MdArrowForwardIos } from "react-icons/md";

const Currency_Trading = ({
    text,
    title,
    image }) => {
        const location = useLocation();

        const getLinkClass = (path) => {
            console.log(path , "path")
            return location.pathname === `${BASE_URL.BASE_URL}${path}` ? 'text-white d-flex justify-content-between' : 'd-flex justify-content-between';
        };
    return (
        
        <div className='Currency_Trading mt-5'>
            <Container>
                <Row>
                    <Col sm={12} md={7} lg={7} className='Currency_Trading_res'>
                        <div className='textDiv'>
                            <h1 className='title'>
                                {title}
                            </h1>
                        </div>
                        <p className="Currency_Trading_text" >{text}</p>
                        <img src={`${BASE_URL.BASE_URL}img/${image}`} alt="Currency_Trading_text" className='Currency_Trading_image' />
                    </Col>
                    <Col sm={12} md={5} lg={5} style={{zIndex:1}}>
                        <div className='Services p-4'>
                            <h2 className='text-white ps-4'>Services</h2>
                            <ul>
                                <li><Link to={`${BASE_URL.BASE_URL}crypto-trading`} className={getLinkClass('crypto-trading')} style={{ width: '100%' }}>Crypto Trading<MdArrowForwardIos /></Link></li>
                                <li><Link to={`${BASE_URL.BASE_URL}real-estate`} className={getLinkClass('real-estate')} style={{ width: '100%' }}>Real Estate <MdArrowForwardIos /></Link></li>
                                <li><Link to={`${BASE_URL.BASE_URL}investment-commodity`} className={getLinkClass('investment-commodity')} style={{ width: '100%' }}>Commodity <MdArrowForwardIos /></Link></li>
                                <li><Link to={`${BASE_URL.BASE_URL}investment-currency-trading`} className={getLinkClass('investment-currency-trading')} style={{ width: '100%' }}>Currency Trading <MdArrowForwardIos /></Link></li>
                                <li><Link to={`${BASE_URL.BASE_URL}mutual-funds`} className={getLinkClass('mutual-funds')} style={{ width: '100%' }}>Mutual Funds <MdArrowForwardIos /></Link></li>
                                <li><Link to={`${BASE_URL.BASE_URL}equity-trading`} className={getLinkClass('equity-trading')} style={{ width: '100%' }}>Equity Trading <MdArrowForwardIos /></Link></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Currency_Trading
