import React from 'react'
import BASE_URL from "../../constant/index";
import GetStarted from '../../components/Aboutus/GetStarted'
import Currency_Trading from './Currency_Trading';
import About_This_Service from './About_This_Service';
import How_We_Work from '../Aboutus/How_We_Work';

const Investment_Currency_Trading = () => {
    const text = "Currency trading, also known as forex trading, is buying or selling currency pairs on the foreign exchange market at specific rates. At Fox Trade, we offer comprehensive currency trading services to help our clients capitalize on the dynamic forex market."
    const title = "Currency Trading"
    const image = "Group 23758.png"
    const aboutitle = "About This Service"
    const AboutService = 'Currency trading involves buying and selling currencies over the counter through a global network of banks, financial institutions, and individual traders.'
    const AboutText = "The currency trading/forex market is the largest and most liquid financial market globally, with an average daily turnover of over US$6.5 trillion. With over 170 currencies traded worldwide, we offer a wide range of currency pairs for trading, including major pairs like USD/EUR and exotic pairs like AUD/JPY. Our expert analysts and traders team monitors the market to identify profitable trading opportunities, providing clients with advanced platforms and tools for efficient trade execution. We offer in-depth market analysis, fundamental analysis, and technical indicators for informed decisions."
    const aboutImage = "Group 23759.png"
    return (
        <div>
            <div className="Investment_Currency_Trading d-flex justify-content-center">
                <div className="about-text">
                    <h1 className="text-white text-center">Currency Trading</h1>
                    <h3 className="text-white  text-center">Fox Trade is the Solution for all problems in your life. Fox Trade is the <br /> Solution for all problems in your life.
                    </h3>
                    <h4 className="text-white  text-center">Restly Investment Currency Trading</h4>
                </div>
                <img
                    src={`${BASE_URL.BASE_URL}img/Mask Group 39@2x.png`}
                    width="5%"
                    className="about-image-tow"
                />
            </div>

            <Currency_Trading title={title} text={text} image={image} />
            <About_This_Service aboutitle={aboutitle} AboutService={AboutService} AboutText={AboutText} aboutImage={aboutImage} />
            <How_We_Work />
            <GetStarted />
        </div>
    )
}
export default Investment_Currency_Trading
