import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import BASE_URL from '../constant/index'

const Factors = () => {
    return (
        <div className='pb-5 mainfactor' >
            <Container>
                <Row>
                    <h1 className='text-center title '>Fox Trade Key Factors</h1>
                    <div className='afterBefore'></div>
                    <h5 className='text-center'>Here are some essential elements to contemplate:</h5>
                </Row>
                <Row className='justify-content-center mt-5'>
                    <Col lg={10} className='mx-auto'>
                        <Row className='gy-4'>
                            <Col md={4} className='d-flex justify-content-center justify-content-lg-end'>
                                <Card className="factorcard pt-4 pb-4">
                                    <Card.Title className="factorCardTitle ps-3 d-flex">
                                        <img src={`${BASE_URL.BASE_URL}img/Group 23391.svg`} alt="hero section" width="25px" className='factorCardImagee me-2' />
                                        <h4 className='mb-0'>Market Opportunity</h4>
                                    </Card.Title>
                                    <Card.Text className="ps-4 pe-4">
                                        Analyzing market dynamics for lucrative investment opportunities is our expertise.
                                    </Card.Text>

                                </Card>
                            </Col>

                            <Col md={4} className='d-flex justify-content-center'>
                                <Card className="factorcard pt-4 pb-4">
                                    <Card.Title className="factorCardTitle ps-3 d-flex">
                                        <img src={`${BASE_URL.BASE_URL}img/Group 23804.png`} alt="hero section" style={{height:'fit-content'}} width="25px" className='factorCardImagee me-2' />
                                        <h4 className='mb-0'>Business Model</h4>
                                    </Card.Title>
                                    <Card.Text className="ps-4 pe-4">
                                        Evaluating business models for long-term viability and profitability is crucial.
                                    </Card.Text>

                                </Card>
                            </Col>

                            <Col md={4} className='d-flex justify-content-center justify-content-lg-start'>
                                <Card className="factorcard pt-4 pb-4">
                                    <Card.Title className="factorCardTitle ps-3 d-flex">
                                        <img src={`${BASE_URL.BASE_URL}img/Group 23398.svg`} alt="hero section" width="25px" className='factorCardImagee me-2' />
                                        <h4 className='mb-0'>Exit Strategy</h4>
                                    </Card.Title>
                                    <Card.Text className="ps-4 pe-4">
                                        Developing a robust exit strategy minimizes risk and optimizes returns
                                    </Card.Text>

                                </Card>
                            </Col>

                            <Col md={4} className='d-flex justify-content-center justify-content-lg-end'>
                                <Card className="factorcard pt-4 pb-4">
                                    <Card.Title className="factorCardTitle ps-3 d-flex">
                                        <img src={`${BASE_URL.BASE_URL}img/Group 23399.svg`} alt="hero section" width="25px" className='factorCardImagee me-2' />
                                        <h4 className='mb-0'>Risk Assessment</h4>
                                    </Card.Title>
                                    <Card.Text className="ps-4 pe-4">
                                        Assessing and managing investment risks is key to long-term success.
                                    </Card.Text>

                                </Card>
                            </Col>

                            <Col md={4} className='d-flex justify-content-center'>
                                <Card className="factorcard pt-4 pb-4">
                                    <Card.Title className="factorCardTitle ps-3 d-flex">
                                        <img src={`${BASE_URL.BASE_URL}img/Group 23400.svg`} alt="hero section" width="25px" className='factorCardImagee me-2' />
                                        <h4 className='mb-0'>Financial Health</h4>
                                    </Card.Title>
                                    <Card.Text className="ps-4 pe-4">
                                        Analyzing financial health ensures informed investment decisions for long-term success.
                                    </Card.Text>

                                </Card>
                            </Col>

                            <Col md={4} className='d-flex justify-content-center justify-content-lg-start'>
                                <Card className="factorcard pt-4 pb-4">
                                    <Card.Title className="factorCardTitle ps-3 d-flex">
                                        <img src={`${BASE_URL.BASE_URL}img/Path 26399.png`} style={{height:'fit-content'}} alt="hero section" width="25px" className='factorCardImagee me-2' />
                                        <h4 className='mb-0'>Scalability</h4>
                                    </Card.Title>
                                    <Card.Text className="ps-4 pe-4">
                                        Assessing scalability ensures sustainable growth for long-term investment success.
                                    </Card.Text>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default Factors
