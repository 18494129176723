import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import Loader from "./components/layout/Loader";
import HeaderNavBar from "./pages/HeaderNavBar";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "../src/assets/Responsive.css";
import Cryptotrading from "./components/CryptoTrading/Cryptotrading";
import ReaclEstate from "./components/CryptoTrading/ReaclEstate";
import Investment_Currency_Trading from "./components/CryptoTrading/Investment _Currency_Trading";
import Investment_commodity from "./components/CryptoTrading/Investment _Commodity";
import Mutual_Funds from "./components/CryptoTrading/Mutual_Funds";
import Equity_Trading from "./components/CryptoTrading/Equity_Trading";
import Register from "./pages/Register";
import { Footer } from "./components/Footer";
import DelayedSuspense from "./components/ui/DelayedSuspense";
import Home from "./pages/Home";
import About from "./pages/AboutUs";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import PrivatePolice from "./pages/PrivatePolice";
import TermsConditions from "./pages/TermsConditions";
import FAQ from "./pages/FAQ";
import ScrollToTop from "./components/layout/ScrollToTop";
import ForgetPassword from "./pages/ForgetPassword";
import AOS from "aos";
import "aos/dist/aos.css";
import BASE_URL from "./constant/index";
import LoginHeader from "./pages/LoginHeader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  // useEffect(() => {
  //   AOS.init();
  // }, []);
  
  return (
    <>
      <ScrollToTop />
      <DelayedSuspense fallback={<Loader />}>
        <Routes>
          <Route
            path={BASE_URL.BASE_URL}
            element={
              <>
                <HeaderNavBar />
                <Outlet />
                <Footer />
              </>
            }
          >
            <Route path={BASE_URL.BASE_URL} element={<Home />} />
            <Route path={`${BASE_URL.BASE_URL}about`} element={<About />} />
            <Route path={`${BASE_URL.BASE_URL}contact`} element={<Contact />} />
            <Route
              path={`${BASE_URL.BASE_URL}crypto-trading`}
              element={<Cryptotrading />}
            />
            <Route
              path={`${BASE_URL.BASE_URL}real-estate`}
              element={<ReaclEstate />}
            />
            <Route
              path={`${BASE_URL.BASE_URL}investment-currency-trading`}
              element={<Investment_Currency_Trading />}
            />
            <Route
              path={`${BASE_URL.BASE_URL}investment-commodity`}
              element={<Investment_commodity />}
            />
            <Route
              path={`${BASE_URL.BASE_URL}mutual-funds`}
              element={<Mutual_Funds />}
            />
            <Route
              path={`${BASE_URL.BASE_URL}equity-trading`}
              element={<Equity_Trading />}
            />
            <Route
              path={`${BASE_URL.BASE_URL}private-police`}
              element={<PrivatePolice />}
            />
            <Route
              path={`${BASE_URL.BASE_URL}terms-conditions`}
              element={<TermsConditions />}
            />
            <Route path={`${BASE_URL.BASE_URL}faq`} element={<FAQ />} />
          </Route>

          {/* <Route path={`${BASE_URL.BASE_URL}Login`} element={<Login />} />
          <Route path={`${BASE_URL.BASE_URL}sign-in`} element={<Register />} /> */}
          <Route path={`${BASE_URL.BASE_URL}Loader`} element={<Loader />} />

          {/* <Route
            path={`${BASE_URL.BASE_URL}forget-password`}
            element={<ForgetPassword />}
          /> */}
          <Route
            path={BASE_URL.BASE_URL}
            element={
              <>
                <LoginHeader />
                <Outlet />
              </>
            }
          >
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </DelayedSuspense>
      {/* <ToastContainer /> */}
    </>
  );
}

export default App;
