import React ,{useState , useEffect ,useRef} from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import BASE_URL from '../constant/index'


const HowItWork = () => {
    const [years, setYears] = useState(0);
    const [clients, setClients] = useState(0);
    const [projects, setProjects] = useState(1100);
    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    animateCount(18, setYears, 0);
                    animateCount(18, setClients, 0);
                    animateCount(1200, setProjects, 1100); // Pass 1100 as the start value
                    observer.unobserve(entry.target);
                }
            },
            {
                root: null,
                threshold: 0.1,
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect();
    }, []);

    const animateCount = (target, setter, start) => {
        const duration = 2000;
        // Calculate the actual difference you want to animate over
        const difference = target - start;
        const stepTime = duration / difference;
        let current = start;

        const interval = setInterval(() => {
            current++;
            setter(current);

            if (current >= target) {
                clearInterval(interval);
            }
        }, stepTime);
    };

    return (
        <div ref={ref} className='howitwork'>
            <Container>
                <Row>
                    <Col md={6} lg={4} xl={6} className="text-center" style={{ position: 'relative' }}>
                        <img src={`${BASE_URL.BASE_URL}img/Group 23777.png`} alt="hero section" width="98%" className='factorCardImage me-2' />
                    </Col>
                    <Col md={12} sm={12} lg={8} xl={6} className='p5 d-flex align-items-center'>
                        <div className='' style={{ width: "100%" }}>
                            <h1 className='title mt-5'>How it Work</h1>
                            <h5 className='text-white'>How Fox Trade Can Help You GROW?</h5>
                            <p className='mt-0 mb-0 mt-md-4 mb-md-0 text-white'>
                                Fox Trade offers a comprehensive range of innovative solutions and services designed to enhance and streamline financial processes. With over 18 years of experience in disrupting traditional banking, investment, and payment systems, we have revolutionized how individuals and businesses manage their finances.
                                <span> From peer-to-peer lending to robo-advisors and mobile payment solutions, we leverage cutting-edge technologies to drive growth and empower our clients to achieve their financial goals</span>
                                <span> With a proven track record of success, a diverse portfolio of satisfied clients, and over 1200 projects completed, Fox Trade is your trusted partner for navigating the complexities of the financial markets and achieving long-term prosperity. Contact us today to learn more about how we can help you grow your investments and secure a brighter financial future.</span>
                            </p>
                            {/* <Button className='primaryButtonbtn mt-4'>
                                Read More <img src={`${BASE_URL.BASE_URL}img/Group 23380.png`} width={"10%"} />
                            </Button> */}
                            <div className='d-flex flex-wrap justify-content-between mt-5 mb-5 gap-3'>
                                <div className='me-1'>
                                    <div>
                                        <h1 className='title mb-0'>{years}<span className="counter-Span">YEARS</span></h1>
                                    </div>
                                    <h5 className='text-white'>
                                        <img src={`${BASE_URL.BASE_URL}img/Path 26706.png`} alt="hero section" width="25px" className='me-2' />
                                        Experience
                                    </h5>
                                </div>
                                <div className='me-1'>
                                    <h1 className='title mb-0'>{clients}<span className="counter-Span">K</span></h1>
                                    <h5 className='text-white'>
                                        <img src={`${BASE_URL.BASE_URL}img/Path 26707.png`} alt="hero section" width="25px" className='me-2' />
                                        Happy Client
                                    </h5>
                                </div>
                                <div className='me-1'>
                                    <h1 className='title mb-0'>{projects}+<span className="counter-Span"></span></h1>
                                    <h5 className='text-white'>
                                        <img src={`${BASE_URL.BASE_URL}img/Path 26708.png`} alt="hero section" width="25px" className='me-2' />
                                        Project Done
                                    </h5>
                                </div>
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
            <img src={`${BASE_URL.BASE_URL}img/Mask Group 43@2x.png`} alt="hero section" width="200" className='howitworkImage' />
        </div>
    )
}

export default HowItWork
