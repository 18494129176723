import React from 'react'
import BASE_URL from '../constant/index';
import { Container, Row, Col } from 'react-bootstrap';
import GetStarted from '../components/Aboutus/GetStarted';
import Accordion from 'react-bootstrap/Accordion';

const FAQ = () => {
    return (
        <div>
            <div className="Terms-Condition d-flex justify-content-center">
                <div className="about-text">
                    <h1 className="text-white text-center">Frequently Asked Questions</h1>
                    <h3 className="text-white  text-center">
                        We Help Client Maximize Value form Profitable in Foxtrade.
                    </h3>
                    <h4 className="text-white  text-center">Restly FAQ</h4>
                </div>
                <img
                    src={`${BASE_URL.BASE_URL}img/Group 22838@2x.png`}
                    width="250px"
                    height={"250px"}
                    className="about-image"
                    alt='FAQ'
                />
                <img
                    src={`${BASE_URL.BASE_URL}img/Mask Group 39@2x.png`}
                    width="5%"
                    className="about-image-tow"
                    alt='FAQ'
                />
            </div>
            <Container>
                <Row className='mt-5'>
                    <Col md='12'>
                        <div className='p-5 whoweareListsection ms-4'>
                            <div style={{
                                borderLeft: "7px solid black",
                                paddingLeft: "30px"
                            }}>
                                <h1 className='title'>
                                    Foxtrade?
                                </h1>
                                <h5>If you're New to Foxtrade or looking to improve the checkout experience on your investment, the guide will help you leran more about our platform and its features.</h5>
                            </div>
                            
                            <Accordion className='mt-5'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What is FoxTrade?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Why Use FoxTrade?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Where is Foxtrade based?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>What is the Network and how does it work?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Col>
                    <Col md='12'>
                        <div className='p-5 whoweareListsection ms-4'>
                            <div style={{
                                borderLeft: "7px solid black",
                                paddingLeft: "30px"
                            }}>
                                <h1 className='title'>
                                    The Foxtrade Diffrence?
                                </h1>
                                <h5>
                                    If you're New to Foxtrade or looking to improve the checkout experience on your investment, the guide will help you leran more about our platform and its features.
                                </h5>
                            </div>
                           
                            <Accordion className='mt-5'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What is FoxTrade?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Why Use FoxTrade?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Where is Foxtrade based?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>What is the Network and how does it work?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Col>
                    <Col md='12'>
                        <div className='p-5 whoweareListsection ms-4'>
                            <div style={{
                                borderLeft: "7px solid black",
                                paddingLeft: "30px"
                            }}>
                                <h1 className='title'>
                                Integrations And Forms Of Payment?
                                </h1>
                                <h5>If you're New to Foxtrade or looking to improve the checkout experience on your investment, the guide will help you leran more about our platform and its features. If you're New to Foxtrade or looking to improve the checkout experience on your investment, the guide will help you leran more about our platform and its features.
                                </h5>
                            </div>
                           
                            <Accordion className='mt-5' defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>How Does Foxtrade 'Future - Proof' Trade?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Col>
                </Row>

            </Container>
            <GetStarted />
        </div>
    )
}

export default FAQ
