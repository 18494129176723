import React from 'react'
import BASE_URL from "../../constant/index";
import GetStarted from '../../components/Aboutus/GetStarted'
import Currency_Trading from './Currency_Trading';
import About_This_Service from './About_This_Service';
import How_We_Work from '../Aboutus/How_We_Work';

const Mutual_Funds = () => {
    const text = "A mutual fund is a professionally managed investment pool that distributes income/profits proportionately among investors, after deducting costs and taxes, by pooling funds with similar investing goals."
    const title = "Mutual Funds"
    const image = "Group 23760.png"
    const aboutitle = "About This Service"
    const AboutService = 'Mutual funds are financial instruments that pool money from multiple investors to purchase a diversified portfolio of stocks, bonds, or other securities.'
    const AboutText = "At Fox Trade, we offer comprehensive mutual fund services aimed at helping our clients achieve their financial goals through strategic investments in professionally managed funds. We have a team of financial advisors who conduct thorough research to identify top-performing mutual funds that align with clients' investment goals and risk tolerance. We assess factors such as fund performance, management expertise, investment strategy, and risk management practices to select funds with potential for long-term growth and stability."
    const aboutImage = "Group 23762.png"
    return (
        <div>
            <div className="Mutual_Funds d-flex justify-content-center">
                <div className="about-text">
                    <h1 className="text-white text-center">Mutual Funds</h1>
                    <h3 className="text-white  text-center">
                    Fox Trade is the Solution for all problems in your life.
                    </h3>
                    <h4 className="text-white  text-center">Restly Investment Mutual Funds</h4>
                </div>

                <img
                    src={`${BASE_URL.BASE_URL}img/Mask Group 39@2x.png`}
                    width="5%"
                    className="about-image-tow"
                    alt='about-image-tow'
                />
            </div>

            <Currency_Trading title={title} text={text} image={image} />
            <About_This_Service aboutitle={aboutitle} AboutService={AboutService} AboutText={AboutText} aboutImage={aboutImage} />
            <How_We_Work />
            <GetStarted />
        </div>
    )
}
export default Mutual_Funds
