import React from 'react'
import BASE_URL from "../constant/index";
const NotFound = () => {
    return (
        <div className='NotFound'>
            <img src={`${BASE_URL.BASE_URL}img/Page Not Found.jpg`} alt="Not found" width={"100%"} height={"auto"}/>
            {/* <h2>Nothing to see here!</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p> */}
        </div>
    )
}

export default NotFound
