import React from 'react'
import BASE_URL from "../../constant/index";
import { Col, Container, Row, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const How_We_Work = () => {
    return (
        <div className='How_We_Work'>
            <Container >
                <div className='How_We_Work_background '>
                    <div>
                        <h1 className='text-white text-center'>How We Work</h1>
                        <h5 className='text-white text-center'>Strategies for Unlocking Your Financial Potential</h5>
                        <p className='text-white text-center mt-4'>We provide best-in-class trading technology platforms, smooth trading executions, and outstanding customer service. Our goal is to create a trading destination <br /> where traders can access a wide range of goods from around the world.</p>
                    </div>
                    <div className="cardsection">
                        <Container>
                            <Row className='g-4'>
                                <Col sm={6} md={6} lg={3} className='How_We_Work_card mb-3'>
                                    <Card className="How_We_Work_card_Section">
                                        <div className='How_We_Work_card_image'>
                                            <img
                                                src={`${BASE_URL.BASE_URL}img/Group 23631.svg`}
                                                width="62px"
                                                height={"62px"}
                                                className="How_We_Work_card_image"
                                                alt='Investment'
                                            />
                                        </div>
                                        <Card.Body className="mt-4">
                                            <h3 className="text-center title">We Work 24/7</h3>
                                            <Card.Text className="text-center">
                                                We offer trading facilities 24 hours a day, five days a week.
                                            </Card.Text>

                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm={6} md={6} lg={3} className='How_We_Work_card mb-3'>
                                    <Card className="How_We_Work_card_Section">
                                        <div className='How_We_Work_card_image'>
                                            <img
                                                src={`${BASE_URL.BASE_URL}img/Group 23745.svg`}
                                                width="62px"
                                                height={"62px"}
                                                className="How_We_Work_card_image"
                                                alt='Investment'
                                            />
                                        </div>
                                        <Card.Body className="mt-4">
                                            <h3 className="text-center title">Leverage</h3>
                                            <Card.Text className="text-center">
                                                The purpose of leverage is to increase profits by borrowing money.
                                            </Card.Text>

                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm={6} md={6} lg={3} className='How_We_Work_card mb-3'>
                                    <Card className="How_We_Work_card_Section">
                                        <div className='How_We_Work_card_image'>
                                            <img
                                                src={`${BASE_URL.BASE_URL}img/Group 23746.svg`}
                                                width="62px"
                                                height={"62px"}
                                                className="How_We_Work_card_image"
                                                alt='Investment'
                                            />
                                        </div>
                                        <Card.Body className="mt-4">
                                            <h3 className="text-center title">All- In- One Facility</h3>
                                            <Card.Text className="text-center">
                                                Client Office functionalities are integrated into the platform.
                                            </Card.Text>

                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm={6} md={6} lg={3} className='How_We_Work_card mb-3'>
                                    <Card className="How_We_Work_card_Section">
                                        <div className='How_We_Work_card_image'>
                                            <img
                                                src={`${BASE_URL.BASE_URL}img/Group 23747.svg`}
                                                width="62px"
                                                height={"62px"}
                                                className="How_We_Work_card_image"
                                                alt='Investment'
                                            />
                                        </div>
                                        <Card.Body className="mt-4">
                                            <h3 className="text-center title">Live Support</h3>
                                            <Card.Text className="text-center">
                                                Live support is available for our <br /> clients.
                                            </Card.Text>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <div className="Related_Services">
                    <h1 className='text-center title mt-5'>Related Services</h1>
                    {/* <Row> */}
                    <Row className="justify-content-md-center">
                        <Col xs lg="3" className='d-flex justify-content-center mb-3 mt-4'>
                            <Card style={{ width: '18rem', border: 'none', overflow: 'hidden' }} className="card-hover">
                                <Link to={`${BASE_URL.BASE_URL}real-estate`}>
                                    <div className="card-image-container">
                                        <img
                                            src={`${BASE_URL.BASE_URL}img/Group 23748.png`}
                                            className="Group_card"
                                            alt='Investment'
                                        />
                                        <div className="hover-text">Real Estate</div>
                                    </div>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs lg="3" className='d-flex justify-content-center mb-3 mt-4'>
                            <Card style={{ width: '18rem', border: 'none', overflow: 'hidden' }} className="card-hover">
                                <Link to={`${BASE_URL.BASE_URL}investment-commodity`}>
                                    <div className="card-image-container">
                                        <img
                                            src={`${BASE_URL.BASE_URL}img/Group 23749.png`}
                                            className="Group_card"
                                            alt='Investment'

                                        />
                                         <div className="hover-text">Commodity</div>
                                    </div>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs lg="3" className='d-flex justify-content-center mb-3 mt-4'>
                            <Card style={{ width: '18rem', border: 'none', overflow: 'hidden' }} className="card-hover">
                                <Link to={`${BASE_URL.BASE_URL}investment-currency-trading`}>
                                    <div className="card-image-container">
                                        <img
                                            src={`${BASE_URL.BASE_URL}img/Group 23750.png`}
                                            className="Group_card"
                                            alt='Investment'
                                        />
                                         <div className="hover-text">Currency Trading</div>
                                    </div>
                                </Link>
                            </Card>
                        </Col>
                    </Row>
                    {/* </Row> */}
                </div>
            </Container>
        </div>
    )
}

export default How_We_Work
