import React from 'react'
import BASE_URL from "../../constant/index";
import GetStarted from '../../components/Aboutus/GetStarted'
import Currency_Trading from './Currency_Trading';
import About_This_Service from './About_This_Service';
import How_We_Work from '../Aboutus/How_We_Work';

const Commodity = () => {
    const title = "Commodity"
    const text = "Commodity traders invest in physical substances like oil, gold, and agricultural products, using futures contracts and physical trading in primary sectors, driven by economic trends or market opportunities."
    const image = "Group 23756.png"
    const aboutitle = "About This Service"
    const AboutService = 'Commodity trading involves buying and selling raw materials or agricultural products like gold, oil, wheat, and coffee on global exchanges.'
    const AboutText = "We offer comprehensive commodity trading services to help clients capitalize on the dynamic market. Our team of expert analysts monitors commodity prices, supply and demand dynamics, geopolitical events, and other factors influencing commodity markets. By providing our clients with access to a wide variety of commodities, we help them diversify and reduce risk. In addition to giving individualized guidance, market research, risk management techniques, and investment advice to help customers reach their financial goals, we offer cutting-edge trading platforms and tools for effective trade execution."
    const aboutImage = "Group 23757.png"
    return (
        <div>
            <div className="Commodity d-flex justify-content-center">
                <div className="about-text">
                    <h1 className="text-white text-center">Commodity</h1>
                    <h3 className="text-white  text-center">
                    Fox Trade is the Solution for all problems in your life.
                    </h3>
                    <h4 className="text-white  text-center">Restly Investment Commodity</h4>
                </div>

                <img
                    src={`${BASE_URL.BASE_URL}img/Mask Group 39@2x.png`}
                    width="5%"
                    className="about-image-tow"
                />
            </div>

            <Currency_Trading title={title} text={text} image={image} />
            <About_This_Service aboutitle={aboutitle} AboutService={AboutService} AboutText={AboutText} aboutImage={aboutImage} />
            <How_We_Work />
            <GetStarted />
        </div>
    )
}

export default Commodity
