import React from 'react'
import BASE_URL from '../constant/index';
import { Container, Row, Col } from 'react-bootstrap';
import GetStarted from '../components/Aboutus/GetStarted';

const TermsConditions = () => {
    return (
        <div>
            <div className="Terms-Condition d-flex justify-content-center">
                <div className="about-text">
                    <h1 className="text-white text-center">Terms and Conditions</h1>
                    <h3 className="text-white  text-center">
                        We Help Client Maximize Value form Profitable in Fox Trade
                    </h3>
                    <h4 className="text-white  text-center">Restly Terms and Conditions</h4>
                </div>
                <img
                    src={`${BASE_URL.BASE_URL}img/Group 22838@2x.png`}
                    width="250px"
                    height={"250px"}
                    className="about-image"
                    alt='about-image'
                />
                <img
                    src={`${BASE_URL.BASE_URL}img/Mask Group 39@2x.png`}
                    width="5%"
                    className="about-image-tow"
                    alt='about-image'
                />
            </div>
            <Container>
                <Row className='mt-5'>
                    <Col md='12'>
                        <div className='ps-5 whoweareListsection me-5'>
                            <div style={{
                                borderLeft: "7px solid black",
                                paddingLeft: "30px"
                            }}>
                                <h1 className='title'>
                                    Terms and Conditions
                                </h1>
                                <h5>Effective Date:</h5>
                            </div>
                            <p className='mt-3'>
                                Welcome to Fox Trade! These Terms and Conditions ("Terms") govern your access to and use of the services provided by Fox Trade, including our website, trading platform, mobile application, and other related services (collectively referred to as the "Services"). By using our services, you agree to these conditions. We kindly ask that you not use our services if you disagree.
                            </p>
                            {/* <ul className='whoweareList'>
                        <li className='mb-2'><MdOutlineTaskAlt style={{ color: '#0094b9' }} className="me-3" />"Sed ut perspiciatis unde omnis iste natus error sit voluptatem. Sed ut perspiciatis unde omnis iste natus error sit voluptatem. </li>
                        <li className='mb-2'><MdOutlineTaskAlt style={{ color: '#0094b9' }} className="me-3" />"Sed ut perspiciatis unde omnis iste natus error sit voluptatem. Sed ut perspiciatis unde omnis iste natus error sit voluptatem.  </li>
                        <li className='mb-2'><MdOutlineTaskAlt style={{ color: '#0094b9' }} className="me-3" />"Sed ut perspiciatis unde omnis iste natus error sit voluptatem. Sed ut perspiciatis unde omnis iste natus error sit voluptatem. t voluptatem.</li>
                        <li className='mb-2'><MdOutlineTaskAlt style={{ color: '#0094b9' }} className="me-3" />"Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</li>
                    </ul> */}
                        </div>
                    </Col>
                    <Col md='12'>
                        <div className='ps-5 whoweareListsection me-5'>
                            <h5>Eligibility</h5>
                            <p>You must be at least 18 years old and have the legal capacity to enter into agreements to use our services. By using our services, you are confirming that you meet these eligibility requirements.</p>
                            <h5 className='mt-3'>Account Registration</h5>
                            <p>You may need to create an account to utilize some aspects of our services. You promise to provide accurate, complete, and up-to-date information during the registration process. Additionally, you pledge to keep your information updated as soon as something changes. You are responsible for monitoring any activity that occurs under your account and for protecting the privacy of your login information.</p>
                            <h5 className='mt-3'>Acceptable Use</h5>
                            <p>You agree to comply with these terms and all applicable laws and regulations by using our services. You may not use our services for any unlawful or prohibited purpose, including but not limited to fraud, money laundering, or illegal activities.</p>

                            <h5 className='mt-3'>Trading and Investments</h5>
                            <p>Our services may include access to trading platforms, investment tools, and financial information. You acknowledge and understand that trading and investing involve risks, and you are solely responsible for evaluating the risks and making investment decisions. Fox Trade does not provide investment advice, and we are not responsible for any losses incurred as a result of your trading or investment activities.</p>

                            <h5 className='mt-3'>Fees and Payments</h5>
                            <p>Certain features or services provided by Fox Trade may be subject to fees or charges. You agree to pay all applicable charges associated with your use of our services. Fox Trade reserves the right to modify or update the fees and payment terms at any time, with prior notice to users.</p>

                            <h5 className='mt-3'>Intellectual Property</h5>
                            <p>The content, trademarks, logos, and other intellectual property displayed on our services are owned by Fox Trade or its licensors. You may not use, reproduce, distribute, or modify any of the content without the express written consent of Fox Trade</p>

                            <h5 className='mt-3'>Privacy Policy</h5>
                            <p>Your use of our services is subject to our privacy policy, which governs the collection, use, and disclosure of your personal information. As stated in our privacy policy, you agree that your information will be collected and used by us when you use our services.</p>

                            <h5 className='mt-3'>Termination</h5>
                            <p>Fox Trade reserves the right to suspend or terminate your access to our services at any time, without prior notice or liability, for any reason, including but not limited to a violation of these terms or fraudulent activities.</p>

                            <h5 className='mt-3'>Disclaimer of Warranties</h5>
                            <p>Our services are offered "AS IS" and "AS AVAILABLE" without any implied or expressed guarantees of any kind. Fox Trade DISCLAIMS ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</p>

                            <h5 className='mt-3'>Limitation of Liability</h5>
                            <p>Fox Trade will not be responsible for any indirect, incidental, special, consequential, or punitive damages, including but not limited to lost profits, data loss, or business interruption.</p>

                            <h5 className='mt-3'>Governing Law and Jurisdiction</h5>
                            <p>These Terms shall be governed by and construed as per the laws. Only these terms shall be enforceable if any disputes result from or are connected to these conditions.
</p>

                            <h5 className='mt-3'>Contact Us</h5>
                            <p>If you have any questions, concerns, or feedback regarding these terms or our services, please contact us at  <b><a href="mailto:support@fxt.exchange">support@fxt.exchange</a> </b>.</p>
                        </div>
                    </Col>

                </Row>

            </Container>
            <GetStarted />
        </div>
    )
}

export default TermsConditions
