import React from 'react'
import Herosection from '../components/Aboutus/Herosection'
import WhoWeAre from '../components/Aboutus/WhoWeAre'
import ThinktoKnow from '../components/Aboutus/ThinktoKnow'
import GetStarted from '../components/Aboutus/GetStarted'
const AboutUs = () => {
  return (
    <div className='aboutus'>
      <Herosection />
      <WhoWeAre/>
      {/* <OurVisionTabs/> */}
      <ThinktoKnow/>
      <GetStarted/>
    </div>
  )
}

export default AboutUs
