import React, { useState, useEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import BASE_URL from '../constant/index'
import { Button } from 'react-bootstrap';
import { motion, AnimatePresence } from "framer-motion";
import { Link } from 'react-router-dom';

const HeroSrction = () => {
    const data = [
        {
            title: 'Looking to navigate the complex world of crypto, equity, and',
            title2: ' currency trading?',
            shortTitle: 'Fox Trade is your trusted partner on the journey to financial success.',
            Image: 'Group 23784.jpg'
        },
        {
            title: 'Elevate your financial strategy and achieve your ',
            title2: ' long-term goals.',
            shortTitle: 'Take the first step towards success with Fox Trade!',
            Image: 'Group 23785.jpg'
        },
        {
            title: 'Fox Trade is your trusted partner on the journey to',
            title2: ' financial success.',
            shortTitle: 'Get expert guidance and innovative solutions to empower you to make informed decisions and achieve your wealth-building goals.',
            Image: 'Group 23786.jpg'
        }
    ]
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };


    const textVariants = {
        hidden: { y: -80, opacity: 0 },
        visible: { y: 0, opacity: 1, transition: { duration: 1 } },
    };

    const buttonVariants = {
        hidden: { y: 50, opacity: 0 },
        visible: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.8 } },
    };

    return (

        <div className='home-section'>
            <Carousel activeIndex={index} onSelect={handleSelect} fade interval={3000000} indicators={false}>
                {data.map((item, idx) => (
                    <Carousel.Item key={idx}>
                         <img
                            src={`${BASE_URL.BASE_URL}img/${item.Image}`}
                            alt="hero section"
                            width="100%"
                        /> 
                        
                        <Carousel.Caption>
                            <AnimatePresence>
                                {index === idx && (
                                    <>
                                        <motion.h1
                                            initial="hidden"
                                            animate="visible"
                                            exit="hidden"
                                            variants={textVariants}
                                            className="Crouserheading"
                                        >
                                            {item?.title}<span style={{ color: '#0194B9' }}>{item?.title2}</span>
                                        </motion.h1>
                                        <img
                                            src={`${BASE_URL.BASE_URL}img/Group 22838.png`}
                                            alt="VisionImages" width={'300'}
                                            height="300"

                                            className='ImageSlider img-fluid' />
                                        <motion.h4
                                            initial="hidden"
                                            animate="visible"
                                            exit="hidden"
                                            variants={textVariants}
                                            className="Crouserheading"
                                        >
                                            {item?.shortTitle}
                                        </motion.h4>
                                        <motion.div
                                            initial="hidden"
                                            animate="visible"
                                            exit="hidden"
                                            variants={buttonVariants}
                                            className="Crouserheading mt-5 gap-3 d-flex flex-wrap justify-content-center"
                                        >

                                            <Link to={`${BASE_URL.BASE_URL}about`} >
                                                <Button className='Carousel-Button me-0'>Learn More</Button>
                                            </Link>
                                            <Link to={`${BASE_URL.BASE_URL}contact`} >
                                                <Button className='CarouselTow-Button me-0'>Contact Us</Button>
                                            </Link>
                                        </motion.div>
                                    </>
                                )}
                            </AnimatePresence>

                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>

        </div>
    )
}

export default HeroSrction;
