import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import BASE_URL from '../constant/index'


const Overview = () => {

    return (
        <div className='mt-5'>
            <Container>
                <Row>
                    <Col md={6} className='px-sm-5 px-3 mb-3 OverViewBorder'>
                        <div style={{
                            borderLeft: "7px solid black",
                            paddingLeft: "30px"
                        }}
                            // data-aos="fade-left"
                        >
                            <h1 className='title'>
                                Overview
                            </h1>
                            <h5>Maximize Your Wealth Potential: Invest with Confidence</h5>
                        </div>
                        <p className='mt-3'
                        //   data-aos="fade-left" data-aos-duration="2000"
                          >
                            We specialize in unlocking the full potential of your wealth, offering a comprehensive range of financial planning and wealth management services tailored to your unique goals and aspirations. Our approach is both sophisticated and straightforward, providing easy-to-understand solutions guided by our team of expert advisors.
                        </p>
                        <p 
                        //  data-aos="fade-left" 
                        // data-aos-duration="2000"
                        >
                            We understand that wealth transcends mere numbers—it reflects your values and aspirations, which is why we take a holistic approach, considering your personal and lifestyle objectives to enhance every aspect of your life. Partner with us today to unlock the potential of your wealth and embark on a journey towards financial success and prosperity
                        </p>
                    </Col>
                    <Col md={6} className='px-sm-5 px-3 mb-3 pt-5' >
                        <div className='idea d-flex ms-2 ms-lg-5 mb-4'
                        //  data-aos="fade-left" data-aos-duration="2000"
                         >
                            <img src={`${BASE_URL.BASE_URL}img/Group 23375.png`} alt="Idea" width={'70px'} height="50px" className='pe-4' />
                            <div>
                                <h2>Ideas</h2>
                                <p>We provide comprehensive financial planning and wealth management solutions, empowering clients to make informed decisions and achieve financial goals, regardless of experience or background.
                                </p>
                            </div>
                        </div>
                        <div className='idea d-flex ms-2 ms-lg-5 mb-4' 
                        // data-aos="fade-left" data-aos-duration="2000"
                        >
                            <img src={`${BASE_URL.BASE_URL}img/Group 23376.png`} alt="Performance" width={'70px'} height="40px" className='pe-4' />
                            <div>
                                <h2>Performance</h2>
                                <p>Our financial planning and wealth management services focus on strategic investment strategies and portfolio management, ensuring global portfolio growth and financial security for clients.
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Overview
