import React from 'react'
import BASE_URL from '../constant/index';
import { Container, Row, Col } from 'react-bootstrap';
import GetStarted from '../components/Aboutus/GetStarted';
const PrivatePolice = () => {
    return (
        <div>
            <div className="Private-Police d-flex justify-content-center">
                <div className="about-text">
                    <h1 className="text-white text-center">Privacy Policy</h1>
                    <h3 className="text-white  text-center">
                        We Help Client Maximize Value form Profitable in Fox trade.
                    </h3>
                    <h4 className="text-white  text-center">Restly Private Policy</h4>
                </div>
                <img
                    src={`${BASE_URL.BASE_URL}img/Group 22838@2x.png`}
                    width="250px"
                    height={"250px"}
                    className="about-image"
                    alt='about-image'
                />
                <img
                    src={`${BASE_URL.BASE_URL}img/Mask Group 39@2x.png`}
                    width="5%"
                    className="about-image-tow"
                    alt='about-image'
                />
            </div>
            <Container>
                <Row className='mt-5'>
                    <Col md='12'>
                        <div className='ps-5 whoweareListsection me-5 mt-3'>
                            <div style={{
                                borderLeft: "3px solid black",
                                paddingLeft: "30px"
                            }}>
                                <h1 className='title'>
                                    Privacy Policy
                                </h1>
                                <h5>Effective Date: </h5>
                            </div>
                            <p className='mt-3'>
                                At Fox Trade, we are committed to protecting the privacy and security of our users' personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our website, trading platform, mobile application, or other services provided by Fox Trade (collectively referred to as the "Services").
                            </p>
                            <h3>Information we collect</h3>
                            <h5>Personal Information:</h5>
                            <p>We may collect personal information such as your name, email address, contact details, and identification documents when you register for an account, verify your identity, or use our services.</p>
                            <ul className='whoweareList'>
                                <li className='mb-2'><b>Account Information:</b>  We may collect information related to your account, including transaction history, trading activity, account preferences, and login credentials.</li>
                                <li className='mb-2'><b>Device Information:</b>We may collect information about your device, such as IP address, browser type, operating system, and device identifiers, to enhance security and improve our services.
                                </li>
                                <li className='mb-2'><b>Cookies and Tracking Technologies:</b>We use cookies, web beacons, and similar technologies to analyze user behavior, customize content, and enhance user experience on our website and platform.</li>

                            </ul>
                        </div>
                    </Col>
                    <Col md='12'>
                        <div className='ps-5 whoweareListsection me-5 mt-3'>
                            <div style={{
                                borderLeft: "3px solid black",
                                paddingLeft: "10px"
                            }}>
                                <h2 className='title'>
                                    How We Use Your Information
                                </h2>
                            </div>
                            <ul className='whoweareList mt-3'>
                                <li className='mb-2'><b>To Provide Services: </b>We use your information to provide, maintain, and improve our services, including account management, transaction processing, customer support, and security measures.
                                </li>
                                <li className='mb-2'><b>To Personalize User Experience:</b>We may use your information to customize content, offers, and recommendations based on your preferences and usage patterns.</li>
                                <li className='mb-2'><b>To Communicate with You:</b>We may use your information to communicate with you about account updates, service announcements, promotional offers, and other relevant information.</li>
                                <li className='mb-2'><b>To Comply with Legal Obligations:</b>We may use your information to comply with legal requirements, enforce our terms and conditions, respond to legal requests, and protect our rights and interests.</li>
                            </ul>
                        </div>
                    </Col>
                    <Col md='12'>
                        <div className='ps-5 whoweareListsection me-5 mt-3'>
                            <div style={{
                                borderLeft: "3px solid black",
                                paddingLeft: "10px"
                            }}>
                                <h2 className='title'>
                                    How We Share Your Information
                                </h2>

                            </div>

                            <ul className='whoweareList mt-3'>
                                <li className='mb-2'><b>Service Providers:</b> We may share your information with trusted third-party service providers who assist us in delivering our services, such as payment processors, identity verification services, and marketing agencies.</li>
                                <li className='mb-2'> <b>Business Partners:</b>We may share your information with business partners, affiliates, or collaborators to offer integrated services, promotions, or joint ventures.</li>
                                <li className='mb-2'><b>Legal Compliance:</b>We may disclose your information to comply with applicable laws, regulations, legal processes, or governmental requests, or to protect the rights, property, or safety of Fox Trade, its users, or others.</li>
                            </ul>
                        </div>
                    </Col>
                    <Col md='12'>
                        <div className='ps-5 whoweareListsection me-5 mt-3'>
                            <div style={{
                                borderLeft: "3px solid black",
                                paddingLeft: "10px"
                            }}>
                                <h2 className='title'>
                                    Your Privacy Choices
                                </h2>

                            </div>

                            <ul className='whoweareList mt-3'>
                                <li className='mb-2'><b>Account Settings:</b> You may access and update your account information, privacy preferences, and communication settings through your account dashboard.</li>
                                <li className='mb-2'><b>Cookies:</b> You can manage cookie preferences through your browser settings or opt out of certain tracking technologies used on our website.</li>
                                <li className='mb-2'><b>Marketing Communications:</b> You can opt out of receiving promotional emails or marketing communications by following the unsubscribe instructions provided in the emails or by contacting us directly</li>
                            </ul>
                        </div>
                    </Col>
                    <Col md='12'>
                        <div className='ps-5 whoweareListsection me-5 mt-3'>
                            <div style={{
                                borderLeft: "3px solid black",
                                paddingLeft: "10px"
                            }}>
                                <h2 className='title'>
                                    Data Security
                                </h2>

                            </div>
                            <p>We safeguard your data against abuse, modification, destruction, and unauthorized access by putting in place industry-standard security measures. However, we cannot guarantee complete security, as there is no 100% safe way to transmit data over the Internet or store electronic files.
                            </p>

                        </div>
                    </Col>
                    <Col md='12'>
                        <div className='ps-5 whoweareListsection me-5 mt-3'>
                            <div style={{
                                borderLeft: "3px solid black",
                                paddingLeft: "10px"
                            }}>
                                <h2 className='title'>
                                    Children's Privacy
                                </h2>

                            </div>
                            <p>
                                Our services are not intended for children under the age of 18, and we do not knowingly collect personal information from minors. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us to request the deletion of that information.
                            </p>

                        </div>
                    </Col>
                    <Col md='12'>
                        <div className='ps-5 whoweareListsection me-5 mt-3'>
                            <div style={{
                                borderLeft: "3px solid black",
                                paddingLeft: "10px"
                            }}>
                                <h2 className='title'>
                                    Updates to this Privacy Policy
                                </h2>

                            </div>
                            <p>
                                This privacy policy may be updated from time to time to reflect modifications to our procedures, applicable laws, or industry standards. Any significant changes will be notified to you through additional contact methods or by publishing the amended policy on our website.
                            </p>

                        </div>
                    </Col>
                    <Col md='12'>
                        <div className='ps-5 whoweareListsection me-5 mt-3'>
                            <div style={{
                                borderLeft: "3px solid black",
                                paddingLeft: "10px"
                            }}>
                                <h2 className='title'>
                                    Contact Us
                                </h2>

                            </div>
                            <p>
                                Please contact us at  <b><a href="mailto:support@fxt.exchange">support@fxt.exchange</a> </b>if you have any queries, concerns, or requests about our data practices or this privacy statement.
                                You agree that your information will be collected, used, and disclosed under this privacy policy when you use our services.
                            </p>

                        </div>
                    </Col>
                </Row>

            </Container>
            <GetStarted />
        </div>
    )
}

export default PrivatePolice
