import React from 'react'
import { Row, Col, Container, Card, Button } from 'react-bootstrap'
import BASE_URL from '../constant/index'
import Carousel from 'react-bootstrap/Carousel';
import { MdArrowRightAlt } from "react-icons/md";
import { Link } from 'react-router-dom'

const WhatWeDo = () => {
    return (
        <div className='What_We_Do overview pt-5'>
            <img src={`${BASE_URL.BASE_URL}img/On_our mission.png`} alt="" width={'130px'} className='uperimage-poss' />
            <img src={`${BASE_URL.BASE_URL}img/On_our mission.png`} alt="" width={'130px'} className='uperimage-poss-right' />
            <Container>
                <Row className=''>
                    <h1 className='text-center title mt-4'>What We Do</h1>
                    <div className='afterBefore'></div>
                    <h5 className='text-center'>Here's an overview of what we do in each area:</h5>
                </Row>
            </Container>
        
            <Carousel fade  indicators={false}>
                <Carousel.Item>
                    <div className='p-sm-5 p-3'>
                        <Row className='justify-content-center'>
                            <Col lg={11} className='mx-auto'>
                                <Row>
                                    <Col sm={12} md={6} lg={6} className='p-4' style={{ position: 'relative' }}>
                                        <img src={`${BASE_URL.BASE_URL}img/Mask Group 19.png`} width={'300px'} height={'450px'} alt="" className='whatwedoImage' />
                                        <Card className='whatwedocard'>
                                            <Card.Body className="p-sm-4 p-3 d-flex flex-column justify-content-center">
                                                <div className='mb-3'>
                                                    <h4 className='font-gilory-m'>Real Estate</h4>
                                                    {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                                                    <Card.Text>
                                                        In the real estate sector, Fox Trade provides investment opportunities and advisory services for individuals and businesses looking to invest in properties. We offer expertise in property valuation, market analysis, and portfolio management to help clients make informed decisions and maximize their returns in the real estate market.
                                                    </Card.Text>
                                                </div>
                                                <Link to={`${BASE_URL.BASE_URL}real-estate`}>
                                                    <Button className='readMore'>Read More <MdArrowRightAlt /></Button>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={12} md={6} lg={6} className='p-4' style={{ position: 'relative' }}>
                                        <img src={`${BASE_URL.BASE_URL}img/Mask Group 24.png`} width={'300px'} height={'450px'} alt="" className='whatwedoImage' />
                                        <Card className='whatwedocard me-lg-4 me-md-0'>
                                            <Card.Body className="p-sm-4 p-3 d-flex flex-column justify-content-center">
                                                <div className='mb-3'>
                                                    <h4 className='font-gilory-m'>Crypto Trading</h4>
                                                    {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                                                    <Card.Text>
                                                        As a leading player in the cryptocurrency market, Fox Trade offers a wide range of crypto trading solutions for both novice and experienced investors. Our platform provides access to a diverse range of cryptocurrencies, advanced trading tools, and real-time market insights to help clients capitalize on opportunities in the rapidly evolving crypto space.
                                                    </Card.Text>
                                                </div>
                                                <Link to={`${BASE_URL.BASE_URL}crypto-trading`}>
                                                    <Button className='readMore'>Read More<MdArrowRightAlt /></Button>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                <div className='p-sm-5 p-3'>
                        <Row className='justify-content-center'>
                            <Col lg={11} className='mx-auto'>
                                <Row>
                                    <Col sm={12} md={6} lg={6} className='p-4' style={{ position: 'relative' }}>
                                        <img src={`${BASE_URL.BASE_URL}img/Mask Group 39.png`} width={'300px'} height={'450px'} alt="" className='whatwedoImage' />
                                        <Card className='whatwedocard'>
                                            <Card.Body className="p-sm-4 p-3 d-flex flex-column justify-content-center">
                                                <div className='mb-3'>
                                                    <h4 className='font-gilory-m'>Commodity Trading</h4>
                                                    {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                                                    <Card.Text>
                                                        Fox Trade facilitates commodity trading activities, allowing clients to invest in a variety of commodities such as gold, silver, oil, and agricultural products. Our platform provides access to global commodity markets, comprehensive market analysis, and risk management tools to help clients navigate commodity trading with confidence.
                                                    </Card.Text>
                                                </div>
                                                <Link to={`${BASE_URL.BASE_URL}investment-commodity`}>
                                                    <Button className='readMore'>Read More <MdArrowRightAlt /></Button>
                                                </Link>                                </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={12} md={6} lg={6} className='p-4' style={{ position: 'relative' }}>
                                        <img src={`${BASE_URL.BASE_URL}img/Mask Group 40.png`} width={'300px'} height={'450px'} alt="" className='whatwedoImage' />
                                        <Card className='whatwedocard me-4'>
                                            <Card.Body className="p-sm-4 p-3 d-flex flex-column justify-content-center">
                                                <div className='mb-3'>
                                                    <h4 className='font-gilory-m'>Currency Trading</h4>
                                                    {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                                                    <Card.Text>
                                                        In the currency trading market, we offer access to the forex market, allowing clients to trade major, minor, and exotic currency pairs. Our platform provides advanced trading features, competitive spreads, and real-time market data to help clients execute trades efficiently and profitably in the dynamic forex market.
                                                    </Card.Text>
                                                </div>
                                                <Link to={`${BASE_URL.BASE_URL}investment-currency-trading`}>
                                                    <Button className='readMore'>Read More <MdArrowRightAlt /></Button>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>

                    </div>
                </Carousel.Item>
                <Carousel.Item>
                <div className='p-sm-5 p-3'>
                        <Row className='justify-content-center'>
                            <Col lg={11} className='mx-auto'>
                                <Row>
                                    <Col sm={12} md={6} lg={6} className='p-4' style={{ position: 'relative' }}>
                                        <img src={`${BASE_URL.BASE_URL}img/Mask Group.png`} width={'300px'} height={'450px'} alt="" className='whatwedoImage' />
                                        <Card className='whatwedocard'>
                                            <Card.Body className="p-sm-4 p-3 d-flex flex-column justify-content-center">
                                                <div className='mb-3'>
                                                    <h4 className='font-gilory-m'>Mutual Funds</h4>
                                                    {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                                                    <Card.Text>
                                                        We offer access to a wide range of mutual funds, including equity funds, bond funds, and balanced funds, catering to various risk profiles and investment objectives. Our platform provides comprehensive fund research, portfolio analysis, and investment planning tools to help clients build diversified investment portfolios and achieve their long-term financial goals.
                                                    </Card.Text>
                                                </div>
                                                <Link to={`${BASE_URL.BASE_URL}mutual-funds`}>
                                                    <Button className='readMore'>Read More <MdArrowRightAlt /></Button>
                                                </Link>                                </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={12} md={6} lg={6} className='p-4' style={{ position: 'relative' }}>
                                        <img src={`${BASE_URL.BASE_URL}img/Mask Group 39.png`} width={'300px'} height={'450px'} alt="" className='whatwedoImage' />
                                        <Card className='whatwedocard me-4'>
                                            <Card.Body className="p-sm-4 p-3 d-flex flex-column justify-content-center">
                                                <div className='mb-3'>
                                                    <h4 className='font-gilory-m'>Equity Trading</h4>
                                                    {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                                                    <Card.Text>
                                                        Equity trading involves buying and selling stocks or shares in publicly traded companies. Our team uses sophisticated strategies to maximize returns, offering access to diverse equities across sectors and markets. Our cutting-edge platforms enable efficient trade execution and provide comprehensive research for informed investment decisions.
                                                    </Card.Text>
                                                </div>
                                                <Link to={`${BASE_URL.BASE_URL}equity-trading`}>
                                                    <Button className='readMore'>Read More <MdArrowRightAlt /></Button>
                                                </Link>                                </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default WhatWeDo
