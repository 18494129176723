import React from 'react'
import { Row, Col, Card, Container } from 'react-bootstrap'
import BASE_URL from '../constant/index'

const Homecard = () => {
    return (
        <div className='home-card'>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={10}>
                        <Row className='gap-4'>
                            <Col mb={4}>
                                <Card className="bottom-card">
                                    <Card.Body className="d-flex">
                                        <div className='me-3'>
                                            <img src={`${BASE_URL.BASE_URL}img/stock.png`} alt="" width={'50'} />
                                        </div>
                                        <div>
                                            <Card.Title>Diverse</Card.Title>
                                            <Card.Title ><b>Trading Options</b></Card.Title>
                                        </div>

                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col mb={4} >
                                <Card className="bottom-card">
                                    <Card.Body className="d-flex">
                                        <div className='me-3'>
                                            <img src={`${BASE_URL.BASE_URL}img/Group 23720.png`} alt="" width={'50'} />
                                        </div>
                                        <div>
                                            <Card.Title>Integration with</Card.Title>
                                            <Card.Title ><b> Banking Sector</b></Card.Title>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col mb={4}>
                                <Card className="bottom-card">
                                    <Card.Body className="d-flex">
                                        <div className='me-3'>
                                            <img src={`${BASE_URL.BASE_URL}img/portfolio (1).png`} alt="" width={'50'} />
                                        </div>
                                        <div>
                                            <Card.Title>Advanced Portfolio</Card.Title>
                                            <Card.Title ><b> Management</b></Card.Title>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default Homecard
