import React from 'react'
import BASE_URL from "../../constant/index";
import GetStarted from '../../components/Aboutus/GetStarted'
import Currency_Trading from './Currency_Trading';
import About_This_Service from './About_This_Service';
import How_We_Work from '../Aboutus/How_We_Work';

const Equity_Trading = () => {
    const text = "Equity trading involves buying and selling shares of companies on the stock market, allowing investors to own a piece of a company and benefit from its growth and profits, but with potential risk."
    const title = "Equity Trading"
    const image = "Group 23764.png"
    const aboutitle = "About This Service"
    const AboutService = 'Equities are a popular investment choice for growth seekers due to their long-term performance, potential higher returns over 3-5 years, and their ability to serve as a hedge against inflation, ensuring long-term purchasing power.'
    const AboutText = "We provides equity trading services to investors, focusing on long-term growth and stability in equities. Our team of financial professionals conducts thorough research, assessing market trends, company fundamentals, and economic indicators to identify investment opportunities. We aim to help investors build diversified portfolios of high-quality stocks that align with their goals and risk tolerance.  Fox Trade emphasizes risk management, providing personalized guidance to navigate market volatility and make informed decisions. We also help our clients recognize dividends as a source of income, focusing on quality companies with strong fundamentals and dividend-paying potential."
    const aboutImage = "Group 23765.png"
    return (
        <div>
            <div className="Equity_Trading d-flex justify-content-center">
                <div className="about-text">
                    <h1 className="text-white text-center">Equity Trading</h1>
                    <h3 className="text-white  text-center">
                    Fox Trade is the Solution for all problems in your life.
                    </h3>
                    <h4 className="text-white  text-center">Restly Investment Equity Trading</h4>
                </div>

                <img
                    src={`${BASE_URL.BASE_URL}img/Mask Group 39@2x.png`}
                    width="5%"
                    className="about-image-tow"
                />
            </div>

            <Currency_Trading title={title} text={text} image={image} />
            <About_This_Service aboutitle={aboutitle} AboutService={AboutService} AboutText={AboutText} aboutImage={aboutImage} />
            <How_We_Work />
            <GetStarted />
        </div>
    )
}

export default Equity_Trading
